export const language = navigator && 
              (navigator.languages && navigator.languages[0] || // Chrome / Firefox
               navigator.language ||   // All browsers
               navigator.userLanguage) 

export const DECIMAL_SEPARATOR_DE = ','
export const round2       = (value) => (Math.round((value + Number.EPSILON) * 100.0) / 100.0)
export const round2String = (value, language) => {
  let result = round2(value).toFixed(2)
  if(language){
    switch(language){
      case 'de':
        result = result.replace('.', DECIMAL_SEPARATOR_DE)
        break
      default:
    }
  }
  return result
}

export const langDecimal = (value, language) => {
  value = value + ''
  if(language){
    switch(language){
      case 'de':
        value = value.replace('.', DECIMAL_SEPARATOR_DE)
        break
      default:
    }
  }
  return value
}